<template>
  <div>
    <heads></heads>
    <div class="banner">
      <img src="../../assets/img/teachers_banner.png" alt="" />
    </div>
    <div class="w">
      <ul class="list d-flex flex-wrap justify-between">
        <li class="item d-flex" v-for="(item, index) in list" :key="index">
          <div class="item-img"><img :src="item.teacherImg" alt="" /></div>
          <div class="item-l">
            <div class="item-name">{{ item.name }}</div>
            <ul class="item-label d-flex align-center flex-wrap">
              <li
                class="d-flex align-center"
                v-for="(items, idx) in item.labelName"
                :key="idx"
              >
                {{ items }}
              </li>
            </ul>
            <div class="item-title">
              {{ item.teacherIntro }}
            </div>
            <div
              class="item-btn d-flex align-center justify-center cursor"
              @click="goDetail(item.id)"
            >
              查看详情>>
            </div>
          </div>
        </li>
      </ul>
      <v-pagination
        v-show="total > display"
        :total="total"
        :current-page="current"
        :display="display"
        @pagechange="pagechange"
      ></v-pagination>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import pagination from "@/components/pagination";
// import { type } from 'os';
// import city from "../../utils/city";
export default {
  data() {
    return {
      flag: true,
      total: 0, // 记录总条数
      display: 9, // 每页显示条数
      current: 1, // 当前的页数
      list: [] //列表
    };
  },
  components: {
    "v-pagination": pagination
  },
  mounted() {
    this.getlist();
  },
  methods: {
    showTag(flag) {
      console.log(flag);
      this.flag = flag;
    },
    pagechange(currentPage) {
      console.log(currentPage); //该参数就是当前点击的页码数
      // ajax请求, 向后台发送 currentPage, 来获取对应的数据
      this.current = currentPage;
      this.getlist();
      const currentY =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollAnimation(currentY, 0);
    },
    // 数据列表
    getlist() {
      let _this = this;
      _this.$api
        .teacherMore({
          start: _this.current,
          limit: _this.display
        })
        .then(res => {
          console.log(res);
          _this.list = res.data;
          _this.total = res.count;
          // _this.list = res.data;
          // this.tabList = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    goDetail(id) {
      this.$router.push({
        name: `teachersDetail`,
        query: {
          id: id
        }
      });
    },
    scrollAnimation(currentY, targetY) {
      // 获取当前位置方法
      // 计算需要移动的距离
      let needScrollTop = targetY - currentY;
      let _currentY = currentY;
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10);
        _currentY += dist;
        window.scrollTo(_currentY, currentY);
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY);
        } else {
          window.scrollTo(_currentY, targetY);
        }
      }, 1);
    }
  }
};
</script>

<style lang="less" scoped>
.banner {
  height: 280px;
  min-width: 1200px;
}

.item {
  width: 380px;
  height: 260px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(222, 222, 222, 0.4);
  padding: 19px 18px 21px 12px;
  box-sizing: border-box;
  margin-top: 30px;

  .item-img {
    height: 220px;
    width: 160px;
    margin-right: 17px;
  }
  .item-l {
    width: 173px;
    position: relative;
    .item-name {
      font-size: 18px;
      color: #333333;
      margin-bottom: 12px;
      position: relative;
      &::after {
        content: "";
        width: 26px;
        height: 3px;
        background: #5f2eff;
        position: absolute;
        left: 0;
        bottom: -12px;
      }
    }
    .item-label {
      padding-top: 12px;
      padding-bottom: 14px;
      box-sizing: border-box;
      border-bottom: 1px dashed #cccccc;
      li {
        padding: 0 7px;
        box-sizing: border-box;
        height: 18px;
        border: 1px solid #5f2eff;
        opacity: 0.6;
        border-radius: 9px;
        font-size: 10px;
        color: #5f2eff;
        opacity: 0.8;
        margin-bottom: 7px;
        margin-right: 10px;
      }
    }
    .item-title {
      width: 173px;
      height: 58px;
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #444444;
      line-height: 20px;
      margin-top: 12px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      white-space: normal !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .item-btn {
      font-size: 14px;
      color: #5f2eff;
      width: 100px;
      height: 28px;
      border: 1px solid #5f2eff;
      border-radius: 14px;
      position: absolute;
      right: 0;
      bottom: 0px;
      &:hover {
        background-image: linear-gradient(176deg, #406bff 0%, #5f2eff 100%),
          linear-gradient(#ff4e4e, #ff4e4e);
        color: #ffffff;
      }
    }
  }
}
</style>
