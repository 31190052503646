<template>
  <div class="d-flex align-center justify-center">
    <ul class="pagination d-flex align-center">
      <li :class="{ disabled: current == 1 }">
        <div
          class="left d-flex justify-center align-center cursor"
          @click="setCurrent(current - 1)"
        >
          <img src="../assets/img/pagination.png" alt="" />
        </div>
      </li>
      <!-- <li :class="{ disabled: current == 1 }">
        <a href="javascript:;" @click="setCurrent(1)"> 首页 </a>
      </li> -->
      <li v-for="p in grouplist" :key="p" :class="{ active: current == p.val }">
        <a
          href="javascript:;"
          class="d-flex justify-center align-center"
          @click="setCurrent(p.val)"
        >
          {{ p.text }}
        </a>
      </li>
      <!-- <li :class="{ disabled: current == page }">
        <a href="javascript:;" @click="setCurrent(page)"> 尾页 </a>
      </li> -->
      <li :class="{ disabled: current == page }">
        <div
          class="right d-flex justify-center align-center cursor"
          @click="setCurrent(current + 1)"
        >
          <img src="../assets/img/pagination.png" alt="" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script type="es6">
export default {
  data() {
    return {
      current: this.currentPage
    }
  },
  props: {
    total: {// 数据总条数
      type: Number,
      default: 0
    },
    display: {// 每页显示条数
      type: Number,
      default: 1
    },
    currentPage: {// 当前页码
      type: Number,
      default: 1
    },
    pagegroup: {// 分页条数
      type: Number,
      default: 3,
      coerce: function (v) {
        v = v > 0 ? v : 5;
        return v % 2 === 1 ? v : v + 1;
      }
    }
  },
  computed: {
    page: function () { // 总页数
      return Math.ceil(this.total / this.display);
    },
    grouplist: function () { // 获取分页页码
      var len = this.page, temp = [], list = [], count = Math.floor(this.pagegroup / 2), center = this.current;
      if (len <= this.pagegroup) {
        while (len--) {
          temp.push({ text: this.page - len, val: this.page - len });
        }
        ;
        return temp;
      }
      while (len--) {
        temp.push(this.page - len);
      }
      ;
      var idx = temp.indexOf(center);
      (idx < count) && (center = center + count - idx);
      (this.current > this.page - count) && (center = this.page - count);
      temp = temp.splice(center - count - 1, this.pagegroup);
      do {
        var t = temp.shift();
        list.push({
          text: t,
          val: t
        });
      } while (temp.length);
      if (this.page > this.pagegroup) {
        (this.current > count + 1) && list.unshift({ text: '...', val: list[0].val - 1 });
        (this.current < this.page - count) && list.push({ text: '...', val: list[list.length - 1].val + 1 });
      }
      return list;
    }
  },
  watch: {
    currentPage(newVal, oldVal) {//对引用类型的值无效
      // console.info('value changed ', newVal)
      this.current = newVal
    }
  },
  methods: {
    setCurrent: function (idx) {
      if (this.current != idx && idx > 0 && idx < this.page + 1) {
        this.current = idx;
        this.$emit('pagechange', this.current);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.pagination {
  margin: 0 auto;
  /*width: 100%;*/
  height: 50px;
  margin-top: 72px;

  li {
    height: 30px;
    border-radius: 5px;
    margin: 3px;
    color: #666;

    & :hover {
      a {
        color: #fff;
        background: linear-gradient(176deg, #406bff 0%, #5f2eff 100%);
      }
    }
    a {
      width: 30px;
      height: 30px;
      background: #ffffff;
      border-radius: 8px;
      font-size: 12px;
      border-radius: 5px;
      text-decoration: none;
    }
    .left {
      width: 30px;
      height: 30px;
      background: #ffffff;
      border-radius: 8px;
      font-size: 12px;
      border-radius: 5px;
      text-decoration: none;
      img {
        width: 6px;
        height: 10px;
      }
    }
    .right {
      width: 30px;
      height: 30px;
      background: #ffffff;
      border-radius: 8px;
      font-size: 12px;
      border-radius: 5px;
      text-decoration: none;
      img {
        width: 6px;
        height: 10px;
        transform: rotate(180deg);
      }
    }
  }
  .active {
    a {
      color: #fff;
      background: linear-gradient(176deg, #406bff 0%, #5f2eff 100%);
    }
  }
}
</style>
